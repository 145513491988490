<template>
  <div :style="{ display: 'flex', gap: '16px' }">
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Editar informações de colaborador"
      icon="EditIcon"
      size="15"
      @click="$router.push(`/editar-colaborador/${member_info.member_id}`)"
    />

    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Dashboard"
      icon="BarChartIcon"
      size="15"
      class="mr-1"
      @click="$router.push(`/usuarios/dashboard/${item.member_id}`)"
    />
    <!-- <feather-icon v-b-tooltip.hover.lefttop.v-primary title="Excluir fornecedor"
    icon="DeleteIcon" size="15" class="ml-1" item:=":this.item"
    @click="checkSupplier" /> -->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'actions-supplier',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: { type: Object, default: () => {} },
  },
  data: () => ({
    member_info: {},
  }),
  created() {
    this.getMemberInfo();
  },
  methods: {
    async getMemberInfo() {
      await this.$store
        .dispatch('getUserMember', {
          id: this.item.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.member_info = { ...this.item, member_id: resp[0].member_id };

          this.getTasks();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkSupplier() {
      await this.$store
        .dispatch('checkSupplierVinculate', {
          id: this.item.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.loading = false;

          if (resp.hasLink === false) {
            this.$emit('open-delete-modal', {
              item: this.item,
              id: this.item.id,
              modalDeleteSupplier: true,
            });

            this.$store.supplier = this.item;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Este fornecedor está vinculado.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
          }
        })
        .catch(() => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro buscar usuário',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
